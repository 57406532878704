import React from 'react';
import classNames from "classnames";

import "./index.css";

const SnapLogo = ({large}) => {
  return (
    <div className={classNames(['SnapLogo', { "SnapLogo__small": !large }])}>
      <div className="SnapLogo--inner-wrapper">
        <div className="SnapLogo--icon">
          <i className="fa-solid fa-camera" />
          <span className="SnapLogo--camera-circle" />
        </div>
        <h1 className={'SnapLogo--mainText'} >Snap <span className="SnapLogo--beta">Beta</span></h1>
        { large && <p className="SnapLogo--subText">Powered by Macmillan</p>}
      </div>
    </div>
  )
}

SnapLogo.defaultProps = {
  large: false
}

SnapLogo.displayName = 'SnapLogo';

export default SnapLogo;