import React from 'react';
import {createRoot} from 'react-dom/client';

import Snap from './snap';

import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Snap />
  </React.StrictMode>
);
