import React, {useLayoutEffect, useEffect, useState} from 'react';
import {apiHost, snapAuthToken} from './config';
import {macmillanEmailRegex} from './constants';

import {USER_COUNTRY} from './constants';

import browserHistory from './browser-history';
import GoogleAnalytics from './utils/google-analytics';

import './snap.css';

import '@macmillanplatform/ui-core/styles';
import '@macmillanplatform/ui-core/fontawesome';
import {Modal} from '@macmillanplatform/ui-core';
import TitleScreen from './pages/title';
import PhotoScreen from './pages/photo';

const localStorage = window.localStorage;

function checkEmail(email) {
  const result = typeof email === 'string' && macmillanEmailRegex.test(email);
  return result;
}

function saveEmail(email, setIsEmailSaved) {
  return () => {
    if (checkEmail(email)) {
      setIsEmailSaved(true);
      localStorage.setItem('email', email);
    } else {
      setIsEmailSaved(false);
      localStorage.setItem('email', '');
    }
  };
}

const Snap = () => {
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailSaved, setIsEmailSaved] = useState(false);
  const [country, setCountry] = useState(USER_COUNTRY);
  const [base64, setBase64] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const modalData = [
    {
      message: 'Oops! No image!',
      subtext: 'Please upload a photo',
    },
    {
      message: 'Favorited!',
      subtext: 'Go to Folio to see in Favorites',
    },
    {
      message: 'Borrowed!',
      subtext: 'Go to Folio to start reading',
    },
    {
      message: 'Uh oh.',
      subtext:
        'There seems to be an error. Make sure your image clearly shows a Macmillan book cover and try again.',
    },
  ];

  const [modalMessage, setModalMessage] = useState(modalData[0]);

  useLayoutEffect(() => {
    return browserHistory.listen(() => {
      GoogleAnalytics.send('pageview');
    });
  }, []);

  useEffect(() => {
    if (checkEmail(email)) {
      setIsEmailValid(true);
      setIsEmailSaved(true);
    }
  }, []);

  useEffect(() => {
    setIsEmailValid(checkEmail(email));
  }, [email]);

  function handleAddFavorite() {
    if (base64) {
      setModalMessage(modalData[1]);
      submitData(base64, email, 'favorite');
    } else {
      setModalMessage(modalData[0]);
      setModalToggle(true);
    }
    return null;
  }

  function handleBorrow() {
    if (base64) {
      setModalMessage(modalData[2]);
      submitData(base64, email, 'borrow');
    } else {
      setModalMessage(modalData[0]);
      setModalToggle(true);
    }
    return null;
  }

  function resetSnap() {
    setModalToggle(false);
  }

  async function submitData(base64, email, action) {
    let image = base64.replace(/^data:.*;base64,/, '');
    setIsLoading(true);
    try {
      const data = {
        image: image,
        email: email,
        country: country,
        action: action,
      };
      const response = await fetch(`${apiHost}snap/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${snapAuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
    } catch (error) {
      setModalMessage(modalData[3]);
    } finally {
      setModalToggle(true);
      setIsLoading(false);
    }
  }

  return (
    <main className="Snap">
      {!(isEmailValid && isEmailSaved) ? (
        <TitleScreen
          email={email}
          setEmail={setEmail}
          saveEmail={saveEmail(email, setIsEmailSaved)}
          country={country}
          setCountry={setCountry}
        />
      ) : (
        <PhotoScreen
          handleAddFavorite={handleAddFavorite}
          handleBorrow={handleBorrow}
          setBase64={setBase64}
          isLoading={isLoading}
          modalToggle={modalToggle}
          setIsEmailSaved={setIsEmailSaved}
          email={email}
        />
      )}
      <Modal className="Snap--modal" show={modalToggle}>
        <h2>{modalMessage.message}</h2>
        <p>{modalMessage.subtext}</p>
        <hr />
        <button onClick={resetSnap}>Close</button>
      </Modal>
    </main>
  );
};

export default Snap;
