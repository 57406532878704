// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Snap {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem;
}
.Snap--modal .modal--modal-content {
  border-radius: 1.5rem;
  width: 90%;
  max-width: 600px;
  text-align: center;
  padding: 0.5rem 1rem;
}
.Snap--modal .modal--modal-content h2 {
  color: var(--primary);
  margin: 1rem auto;
}
.Snap--modal .modal--modal-content hr {
  height: 1px;
  background-color: var(--lighter-gray);
  border: none;
  margin: auto;
}
.Snap--modal .modal--modal-content button {
  font-size: 1.17rem;
  padding: 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/snap.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,OAAO;EACP,sBAAsB;EACtB,oBAAoB;EACpB,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,qCAAqC;EACrC,YAAY;EACZ,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".Snap {\n  position: relative;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: stretch;\n  padding: 1rem;\n}\n.Snap--modal .modal--modal-content {\n  border-radius: 1.5rem;\n  width: 90%;\n  max-width: 600px;\n  text-align: center;\n  padding: 0.5rem 1rem;\n}\n.Snap--modal .modal--modal-content h2 {\n  color: var(--primary);\n  margin: 1rem auto;\n}\n.Snap--modal .modal--modal-content hr {\n  height: 1px;\n  background-color: var(--lighter-gray);\n  border: none;\n  margin: auto;\n}\n.Snap--modal .modal--modal-content button {\n  font-size: 1.17rem;\n  padding: 0.75rem;\n  border: none;\n  background: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
