export const macmillanEmailRegex = /.*\@macmillan\.com$/;

export const ENVIRONMENTS = {
  PRODUCTION: 'prd',
  STAGING: 'stg',
  DEVELOPMENT: 'dev',
};

export const GOOGLE_ANALYTICS_CODES = {
  [ENVIRONMENTS.DEVELOPMENT]: '',
  [ENVIRONMENTS.STAGING]: 'G-PNZ50TH1C7',
  [ENVIRONMENTS.PRODUCTION]: 'G-03VFTDKN28',
};

const USER_COUNTRY_BY_TIMEZONE = {
  au: ['Australia/', 'Antarctica/Macquarie'],
  uk: ['Europe/London'],
  us: ['America/', 'Pacific/Honolulu'],
};

export const COUNTRY_LIST = {
  au: 'Australia',
  uk: 'United Kingdom',
  us: 'United States',
};

const getUserCountryByTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  for (const country in USER_COUNTRY_BY_TIMEZONE) {
    const timezones = USER_COUNTRY_BY_TIMEZONE[country];

    if (!timezones.some(tz => timezone.indexOf(tz) !== -1)) continue;
    return country;
  }
  return 'us';
};

export const USER_COUNTRY = getUserCountryByTimezone();
