// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: var(--dark-gray);
  display: block;
  padding-top: .5rem;
}

.Footer-login {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--primary);
}

.Footer-login span {
  border-bottom: 2px solid var(--primary);
  padding-bottom: .1rem;
  margin: auto .5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/photo/components/footer/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,uCAAuC;EACvC,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".Footer {\n  position: relative;\n  width: 100%;\n  text-align: center;\n  font-size: 0.8rem;\n  color: var(--dark-gray);\n  display: block;\n  padding-top: .5rem;\n}\n\n.Footer-login {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: var(--primary);\n}\n\n.Footer-login span {\n  border-bottom: 2px solid var(--primary);\n  padding-bottom: .1rem;\n  margin: auto .5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
