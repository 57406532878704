import {SetupAnalytics} from '@macmillanplatform/ui-core/utils';

import {env} from '../config';
import {GOOGLE_ANALYTICS_CODES} from '../constants';

const [GoogleAnalytics, pageAnalytics] = SetupAnalytics(
  env,
  GOOGLE_ANALYTICS_CODES[env]
);

export {pageAnalytics};
export default GoogleAnalytics;
