export const resizeImage = result => {
  return new Promise(resolve => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    console.log(`original: ${result}`);

    img.onload = () => {
      const max = 1000;
      let width = img.width;
      let height = img.height;
      let newWidth = width;
      let newHeight = height;

      if (width > height && width > max) {
        newWidth = max;
        newHeight = (height / width) * newWidth;
      }
      if (height > width && height > max) {
        newHeight = max;
        newWidth = (width / height) * newHeight;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      const newResult = canvas.toDataURL('image/jpeg', 0.8);
      resolve(newResult);
    };

    img.src = result;
  });
};
