import React, {useEffect, useState} from 'react';
import Footer from './components/footer';
import SnapButton from './components/snap-button';
import SnapLogo from '../../components/snap-logo';

import './index.css';

import CameraImage from './assets/folio-camera.svg';
import macLogo from './assets/mac-logo.svg';
import folioIcon from './assets/folio-icon.svg';
import folioIconWhite from './assets/folio-icon-white.svg';
import {resizeImage} from './helpers/resize-image';

const PhotoScreen = props => {
  const {
    handleAddFavorite,
    handleBorrow,
    setBase64,
    isLoading,
    modalToggle,
    setIsEmailSaved,
    email,
  } = props;
  const [hideButtons, setHideButtons] = useState(true);
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  useEffect(() => {
    if (!modalToggle) {
      setFile(null);
      setFileDataURL(null);
      setHideButtons(true);
    }
  }, [modalToggle]);

  useEffect(() => {
    let fileReader;
    if (!file) return;
    fileReader = new FileReader();
    fileReader.onload = e => {
      const {result} = e.target;
      getResizedImage(result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  async function getResizedImage(dataURL) {
    const newDataURL = await resizeImage(dataURL);
    setFileDataURL(newDataURL);
    const base64 = newDataURL.split(',')[1];
    setBase64(base64);
  }

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null);
      setHideButtons(true);
      return;
    }
    const file = e.target.files[0];
    setFile(file);
    setHideButtons(false);
    e.target.value = null;
  };

  return (
    <div className="PhotoScreen__main">
      <div className="PhotoScreen__header">
        <SnapLogo large={false} />
        <div className="PhotoScreen__mac-logo">
          <img src={macLogo} alt="Macmillan Logo" />
        </div>
      </div>
      <div className="PhotoScreen__upload">
        <div className="PhotoScreen__upload-inner">
          <img
            className="PhotoScreen__camera-icon"
            src={CameraImage}
            alt="Tap here to snap a picture of the book you'd like to add to your Folio personal library or favorites."
          />
          <h3>
            Tap here to snap a picture of the book you'd like to add to your
            Folio personal library or favorites.
          </h3>
        </div>
        {fileDataURL && (
          <img
            className="PhotoScreen__preview"
            src={fileDataURL}
            alt="Image Preview"
          />
        )}
        <input
          type="file"
          accept="image/*,text/plain"
          onChange={onSelectFile}
        />
      </div>

      {hideButtons ? (
        <Footer setIsEmailSaved={setIsEmailSaved} email={email} />
      ) : (
        <div className="PhotoScreen__buttons">
          <SnapButton
            isDisabled={hideButtons || isLoading}
            onClickFunc={handleAddFavorite}
            isLoading={isLoading}
            iconImage={folioIconWhite}
            btnText={'Add to Folio Favorites'}
            outlined={false}
          />
          <SnapButton
            isDisabled={hideButtons || isLoading}
            onClickFunc={handleBorrow}
            isLoading={isLoading}
            iconImage={folioIcon}
            btnText={'Borrow in Folio'}
            outlined={true}
          />
          <Footer setIsEmailSaved={setIsEmailSaved} email={email} />
        </div>
      )}
    </div>
  );
};

export default React.memo(PhotoScreen);
