// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0 auto;
  padding: 0rem;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--text);
  max-width: 45rem;
  background: var(--lighter-gray);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

:root {
  --primary: #ab0033;
  --primary-100: #fddfe8;
  --primary-200: #ffd6e2;
  --primary-300: #bf315e;
  --primary-rgba: rgba(171, 0, 51, 0.2);
  --primary-light: #e8003c;

  --secondary: #264cef;
  --triadic: #4cef26;
  --dark-green: #285d00;

  --light: #fefbfb;

  --lighter-gray: #e6e6e6;
  --light-gray: #c4c4c4;
  --dark-gray: #999;
  --darker-gray: #7e7e7e;

  --text: #333333;
  --alert: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;EACd,aAAa;EACb,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;;;EAGE,sBAAsB;EACtB,kCAAkC;EAClC,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,qCAAqC;EACrC,wBAAwB;;EAExB,oBAAoB;EACpB,kBAAkB;EAClB,qBAAqB;;EAErB,gBAAgB;;EAEhB,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,sBAAsB;;EAEtB,eAAe;EACf,uBAAuB;AACzB","sourcesContent":["html,\nbody {\n  margin: 0 auto;\n  padding: 0rem;\n  font-family: 'Roboto', sans-serif;\n  font-size: 16px;\n  color: var(--text);\n  max-width: 45rem;\n  background: var(--lighter-gray);\n}\n\n#root {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-font-smoothing: antialiased;\n}\n\n:root {\n  --primary: #ab0033;\n  --primary-100: #fddfe8;\n  --primary-200: #ffd6e2;\n  --primary-300: #bf315e;\n  --primary-rgba: rgba(171, 0, 51, 0.2);\n  --primary-light: #e8003c;\n\n  --secondary: #264cef;\n  --triadic: #4cef26;\n  --dark-green: #285d00;\n\n  --light: #fefbfb;\n\n  --lighter-gray: #e6e6e6;\n  --light-gray: #c4c4c4;\n  --dark-gray: #999;\n  --darker-gray: #7e7e7e;\n\n  --text: #333333;\n  --alert: var(--primary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
