import React from 'react';
import './index.css';

const Footer = props => {
  const {setIsEmailSaved, email} = props;
  return (
    <div className="Footer">
      <p className="Footer-login">
        Logged in as: <b>{email}</b>{' '}
        <span onClick={() => setIsEmailSaved(false)}>Change</span>
      </p>
      <p>
        <b>Powered by Macmillan</b>
      </p>
      <p>Platform Development • {new Date().getFullYear()}</p>
    </div>
  );
};

export default Footer;
