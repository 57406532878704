// TODO: remove this file when snap will support configs.js from Ansible

import {ENVIRONMENTS} from './constants';

export const getEnvironment = () => {
  const host = window.location.host;
  if (host === 'localhost:3000') return ENVIRONMENTS.DEVELOPMENT;
  if (host.indexOf('-stg.macmillan') !== -1) return ENVIRONMENTS.STAGING;
  return ENVIRONMENTS.PRODUCTION;
};

export const env = getEnvironment();

export const AUTH_TOKENS = {
  [ENVIRONMENTS.DEVELOPMENT]: '',
  [ENVIRONMENTS.STAGING]: '7zPLZ_pVebfHZdFwwBpPuLv10hKwwN2gFH4wVZMV8Bc',
  [ENVIRONMENTS.PRODUCTION]: '48s2CZFfVwR9yWp8kYNi0X4JBR-ygjSE8E-0lpEQOZ0',
};
export const API_HOSTS = {
  [ENVIRONMENTS.DEVELOPMENT]: 'http://localhost:3000/api/v1/',
  [ENVIRONMENTS.STAGING]: 'https://snap-stg.macmillan-platform.com/api/v1/',
  [ENVIRONMENTS.PRODUCTION]: 'https://snap.macmillan-platform.com/api/v1/',
};

export const apiHost = API_HOSTS[env];
export const appStoreUrl =
  'https://apps.apple.com/us/app/macmillan-publishing/id6445850725';
export const playMarketUrl =
  'https://play.google.com/store/apps/details?id=uk.co.glassboxx.macus';
export const snapAuthToken = AUTH_TOKENS[env];
